import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { uploadsSelectors } from "~/redux/state/uploads"
import { RootState } from "~/redux/store"

export const useUploadEntities = (ids: EntityId[]) => {
  const uploads = useSelector<RootState, UploadEntity[]>(state =>
    uploadsSelectors.selectByIds(state, ids)
  )

  return { uploads }
}
