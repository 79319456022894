import { Stack } from "@chakra-ui/react"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useUnmount } from "react-use"
import { useInitialiseFieldsList } from "~/hooks/useInitialiseFieldsList"
import { unregisterFields } from "~/services/Utils"
import EmailInput from "../fields/EmailInput"
import FormInput from "../fields/FormInput"
import PhoneInput from "../fields/PhoneInput"
import FormStack from "../FormStack"

// keep this list ordered by UI elements
const fields = ["fullName", "email", "phoneNumber", "country", "terms"]

interface Props {}

const PickupFormDetailsDefault: React.FC<Props> = () => {
  const {
    register,
    unregister,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext()
  const { t } = useTranslation()
  useUnmount(() => {
    unregisterFields(unregister, fields)
  })

  useInitialiseFieldsList(fields)

  const countryCode = watch("country")

  return (
    <FormStack>
      <FormInput
        label={t(
          "components.checkout.pickup-forms.PickupFormDetailsDefault.FullName"
        )}
        type="text"
        autoComplete="name"
        errors={errors}
        defaultValue={getValues("fullName")}
        {...register("fullName", {
          required: true,
        })}
      />
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <EmailInput />

        <PhoneInput
          label={t(
            "components.checkout.pickup-forms.PickupFormDetailsDefault.PhoneNumber"
          )}
          errors={errors}
          countryCode={countryCode}
          defaultValue={getValues("phoneNumber")}
          {...register("phoneNumber", {
            required: true,
          })}
        />
      </Stack>
    </FormStack>
  )
}

export default PickupFormDetailsDefault
