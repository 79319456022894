import { PaymentMethods } from "@jackfruit/common"
import { useOrderSummary } from "~/hooks/useOrderSummary"
import { usePageCartClientPrintService } from "~/hooks/usePageCartClientPrintService"
export const PAYMENT_METHOD_KEY = "payment.method"

export const usePaymentOption = () => {
  const {
    clientPrintService: { paymentConfig },
    pageCart: { couponCodeStatus },
  } = usePageCartClientPrintService()
  const {
    orderSummary: {
      data: { totalFloat },
    },
  } = useOrderSummary()

  const couponAccepted = couponCodeStatus === "accepted"
  const isFree = totalFloat === 0 && couponAccepted

  const options = Object.keys(paymentConfig) as PaymentMethods[]
  const hasStripe = options[0] === "stripe"
  const hasPayInStore = options[0] === "payInStore"

  return { option: options[0], hasPayInStore, hasStripe, isFree }
}
