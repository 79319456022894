import {
  BoxProps,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react"
import React, { useCallback, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useTranslation } from "react-i18next"
import { useCart } from "~/hooks/useCart"
import { usePrintService } from "~/hooks/usePrintService"
import { ReactComponent as BackgroundSvg } from "./assets/couponCodeInputBgMcDonald.svg"

interface Props extends BoxProps {}

const CouponCodeInputMcDonald: React.FC<Props> = ({ ...props }) => {
  const backgroundImage = useMemo(
    () => btoa(ReactDOMServer.renderToString(<BackgroundSvg />)),
    []
  )

  const { t } = useTranslation()
  const { cart, cartActions } = useCart()
  const { printService } = usePrintService(cart.printServiceId)

  const [couponInput, setCouponInput] = useState<string>(cart.couponCode)

  const couponIsLoading = cart.couponCodeStatus === "loading"
  const couponIsValid = cart.couponCodeStatus === "accepted"
  const couponIsInvalid = cart.couponCodeStatus === "rejected"

  const onCouponApply = useCallback(() => {
    cartActions.setCoupon(couponInput)
  }, [couponInput, cartActions])

  const onCouponChange = useCallback(() => {
    setCouponInput("")
    cartActions.setCoupon("") // Apply an empty coupon to reset
  }, [setCouponInput, cartActions])

  const isCouponAvailable = printService && !!printService.hasCoupon
  if (!isCouponAvailable) {
    return null
  }

  return (
    <Flex
      {...props}
      w="full"
      backgroundImage={`url(data:image/svg+xml;base64,${backgroundImage})`}
      backgroundPosition="center"
      backgroundColor="#da291c"
      borderRadius="lg"
      flexDirection="column"
      px={10}
      py={6}
    >
      <Flex flex={1}>
        <Flex alignItems="center" flex={1}>
          <Text color="white" fontSize="md" fontWeight="bold" pr={2}>
            {t("components.checkout.fields.CouponCodeInput.Title")}
          </Text>
        </Flex>
        <Flex flex={1} position="relative" alignItems="center">
          <InputGroup>
            <Input
              borderColor="gray.200"
              backgroundColor="white"
              id="p-checkout-coupon"
              data-testid="p-checkout-coupon"
              name="couponCode"
              placeholder={t(
                "components.checkout.fields.CouponCodeInput.Input"
              )}
              autoComplete="off"
              value={couponInput}
              onChange={e => setCouponInput(e.target.value)}
              disabled={couponIsValid}
              _disabled={{ backgroundColor: "gray.200" }}
              isInvalid={couponIsInvalid}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  onCouponApply()
                }
              }}
            />

            <InputRightElement
              padding={0}
              width="auto"
              children={
                couponIsValid ? (
                  <Button
                    id="p-customer-remove-coupon" // GTM: remove coupon
                    colorScheme="gray"
                    variant="link"
                    width="5.5rem"
                    onClick={onCouponChange}
                  >
                    {t("components.checkout.fields.CouponCodeInput.Change")}
                  </Button>
                ) : (
                  <Button
                    colorScheme="primary"
                    variant="link"
                    width="5.5rem"
                    onClick={onCouponApply}
                    isLoading={couponIsLoading}
                  >
                    {t("components.checkout.fields.CouponCodeInput.Apply")}
                  </Button>
                )
              }
            />
          </InputGroup>
        </Flex>
      </Flex>
      <Flex alignItems="center" flex={1}>
        <Flex flex={1} />
        <Flex flex={1}>
          {couponIsValid && (
            <Text fontSize="sm" color="white">
              {t("components.checkout.fields.CouponCodeInput.IsValid")}
            </Text>
          )}

          {couponIsInvalid && (
            <Text fontSize="sm" color="white">
              {t("components.checkout.fields.CouponCodeInput.Invalid")}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CouponCodeInputMcDonald
